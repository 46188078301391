import React from 'react';
import Content from '../views/Leasing/Price';

export default ({location}) => <Content location={location} seo={{
  title: 'Hvad koster en løsning til køretøjshåndtering for leasingselskaber?',
  lang: 'da-DK',
  description: 'Vil du have en pris på en connected car løsning til leasingselskaber? Udfyld vores korte formular og modtag et tilbud.',
  meta: [{
    name: 'keywords',
    content: 'Connected car løsning til leasingselskaber'
  }]
}}/>